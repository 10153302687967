export const experienceData = [
    {
        id: 1,
        company: 'DILLI VIBES',
        jobtitle: 'Freelancers WORK ( MERN STACK WEB & SEO)',
        startYear: 'MARCH 2024',
        endYear: 'PRESENT'
    },
    {
        id: 2,
        company: 'TheWebPatriot',
        jobtitle: 'Full Stack Developer (MERN Stack)',
        startYear: 'Oct 2022',
        endYear: 'FEB 2024'
    },
    {
        id: 3,
        company: 'Masai School',
        jobtitle: 'Full Stack Developer (MERN Stack)',
        startYear: 'Oct 2021',
        endYear: 'Sep 2022'
    }
    
    
]
